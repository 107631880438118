import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "enFooter",
  "leftColumn": {
    "title": "ZIMPLY INNOVATION",
    "city": "Stockholm",
    "adress": "Artillerigatan 42",
    "postCode": "114 45 Stockholm",
    "city2": "Göteborg",
    "adress2": "Jons väg 21",
    "postCode2": "433 75 Jonsered"
  },
  "middleColumn": {
    "title": "CONTACT",
    "line1": "Zimply Innovation",
    "line2": "08 122 09 339",
    "link": "hello@zimply.ai"
  },
  "rightColumn": {
    "title": "SOCIAL",
    "fb": "/img/fb.png",
    "fbPath": "https://www.facebook.com/zimplyinnovation",
    "linkedIn": "/img/linkdin.png",
    "linkedinPath": "https://www.linkedin.com/company/zimply-innovation/",
    "insta": "/img/instagram.png",
    "instaPath": "https://www.instagram.com/zimplyinnovation/"
  },
  "logo": "/img/zimply-text-white-2.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      